<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Dato Financiero - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
                <b-col md="2">
                  <b-form-group label="TEA:">
                    <b-form-input disabled type="number" class="text-right" step="any" v-model="financial_data.tea"></b-form-input>
                    <small v-if="errors.tea"  class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="TMA:">
                    <b-form-input disabled type="number" class="text-right" step="any" v-model="financial_data.tma"></b-form-input>
                    <small v-if="errors.tma"  class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Seg. Desgravamen :">
                    <b-form-input disabled type="number" class="text-right" step="any" v-model="financial_data.relief_insurance"></b-form-input>
                    <small v-if="errors.relief_insurance"  class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Seg. Multiriesgo:">
                    <b-form-input disabled type="number" class="text-right" step="any" v-model="financial_data.multirisk_insurance"></b-form-input>
                    <small v-if="errors.multirisk_insurance"  class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="ITF:">
                    <b-form-input disabled type="number" class="text-right" step="any" v-model="financial_data.itf"></b-form-input>
                    <small v-if="errors.itf"  class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Otros:">
                    <b-form-input disabled type="number" class="text-right" step="any" v-model="financial_data.other"></b-form-input>
                    <small v-if="errors.other"  class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col> 

                <b-col md="8">
                  <b-form-group label="Observación:">
                    <b-form-input disabled type="text" v-model="financial_data.observation"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Por Defecto:">
                    <b-form-select disabled v-model="financial_data.default" :options="defaults"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Estado:">
                    <b-form-select disabled v-model="financial_data.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>


                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/dato-financiero/listar' }" append >REGRESAR</b-link>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props: ["id_financial_data"],
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'FinancialData',
      role: 3,
      financial_data: {
          id_financial_data:'',
          tea:'0.00',
          tem:'0.00',
          ted:'0.00',
          tma:'0.00',
          tmd:'0.00',
          relief_insurance:'0.00',
          multirisk_insurance:'0.00',
          itf:'0.00',
          other:'0.00',
          default:1,
          state:1,
      },
      defaults:[
        {value:'1',text:'Si'},
        {value:'0',text:'No'},
      ],
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],

      errors: {
        tea: false,
        tma: false,
        relief_insurance: false,
        multirisk_insurance: false,
        itf: false,
        other: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewFinancialData();
  },
  methods: {
    EditFinancialData,
    Validate,
    ViewFinancialData,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ViewFinancialData() {
  let me = this;
  let id_financial_data = je.decrypt(this.id_financial_data);
  let url = me.url_base + "financial-data/view/"+id_financial_data;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {

        me.financial_data.id_financial_data = response.data.result.id_financial_data;
        me.financial_data.tea = response.data.result.tea;
        me.financial_data.tem = response.data.result.tem;
        me.financial_data.ted = response.data.result.ted;
        me.financial_data.tma = response.data.result.tma;
        me.financial_data.tmd = response.data.result.tmd;
        me.financial_data.relief_insurance = response.data.result.relief_insurance;
        me.financial_data.multirisk_insurance = response.data.result.multirisk_insurance;
        me.financial_data.itf = response.data.result.itf;
        me.financial_data.other = response.data.result.other;
        me.financial_data.default = response.data.result.default;
        me.financial_data.state = response.data.result.state;
        
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function EditFinancialData() {
  let me = this;
  let url = me.url_base + "financial-data/edit";
  let data = me.financial_data;
  me.isLoading = true;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.tea = this.financial_data.tea.length == 0 ? true : false;
  this.errors.tma = this.financial_data.tma.length == 0 ? true : false;
  this.errors.relief_insurance = this.financial_data.relief_insurance.length == 0 ? true : false;
  this.errors.multirisk_insurance = this.financial_data.multirisk_insurance.length == 0 ? true : false;
  this.errors.itf = this.financial_data.itf.length == 0 ? true : false;
  this.errors.other = this.financial_data.other.length == 0 ? true : false;
  

  if (this.errors.tea) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.tma) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.relief_insurance) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.multirisk_insurance) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.itf) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.other) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el dato financiero ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditFinancialData();
    }
  });
}
</script>
